/*Body font*/
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/*scrollbar width */
::-webkit-scrollbar {
    width: 4px;
}

/*scrollbar Track */
::-webkit-scrollbar-track {
    border-radius: 1px;
}

/*scrollbar Handle */
::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 9px;
}

/* To prevent white space bouncing, i.e when white space shows up during overscroll*/
html{
    overflow: hidden;
    height: 100%;
}

body{
    overflow: auto;
    height: 100%;
}